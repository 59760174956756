
































































































































import { defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { coreApi } from "@/lib/backend";
import type { PaginatedScheduledNotificationViewModel } from "@/lib/backend/core.api";
import { badgeVariants } from "@/lib/pfg/components/badge";
import { buttonVariants } from "@/lib/pfg/components/button";
import { cardVariants } from "@/lib/pfg/components/card";
import { inputVariants } from "@/lib/pfg/components/input";
import { labelVariants } from "@/lib/pfg/components/label";
import { skeletonVariants } from "@/lib/pfg/components/skeleton";
import { textareaVariants } from "@/lib/pfg/components/textarea";
import { cx } from "@/lib/style/cva.config";
import { formatDate } from "@/utils/date";

export default defineComponent({
	props: {
		memberId: {
			type: Number,
			required: true,
		},
		messageId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const { status, data } = useQuery({
			queryKey: ["member", props.memberId, "scheduled-notifications"] as const,
			queryFn: async (context) =>
				await coreApi.members
					.memberNotificationControllerGetScheduledNotifications(context.queryKey[1], {
						signal: context.signal,
					})
					.then((response) => response.data),
			select: (data: PaginatedScheduledNotificationViewModel) =>
				data.data.find((scheduledNotification) => scheduledNotification.id === props.messageId),
		});

		return {
			status,
			data,
			formatDate,
			cx,
			cardVariants,
			buttonVariants,
			badgeVariants,
			labelVariants,
			inputVariants,
			textareaVariants,
			skeletonVariants,
		};
	},
	watch: {
		status(newValue: "loading" | "success" | "error") {
			if (newValue !== "success" || !!this.data) {
				return;
			}

			this.$router.replace({ name: "Gebruiker berichten" });
		},
	},
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.cx(
			_vm.cardVariants({
				class:
					'flex flex-1 flex-col gap-3 rounded-2xl border-purple-lighter bg-purple-background p-4 shadow-none',
			}),
			_vm.$attrs.class
		)},[_c('div',{staticClass:"flex items-center justify-between gap-4 pt-2"},[_c('h2',{staticClass:"m-0 text-lg font-bold normal-case leading-7 text-black"},[_vm._v("Ingeplande bericht")]),_c('router-link',{class:_vm.buttonVariants({
					size: 'icon',
					class:
						'rounded-full bg-purple-lighter p-2.5 text-black hover:bg-purple-lighter hover:bg-opacity-60 active:bg-purple-lighter active:bg-opacity-60',
				}),attrs:{"to":{ name: 'Gebruiker berichten' }}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18 18 6M6 6l12 12"}})])])],1),(_vm.status === 'loading')?[_c('div',{staticClass:"flex flex-col gap-1"},[_c('label',{class:_vm.labelVariants({ class: 'leading-5 text-gray-600' }),attrs:{"for":"message_title"}},[_vm._v(" Titel ")]),_c('div',{class:_vm.skeletonVariants({ class: 'h-10 rounded-lg bg-white' })})]),_c('div',{staticClass:"flex flex-1 flex-col gap-1"},[_c('label',{class:_vm.labelVariants({ class: 'leading-5 text-gray-600' }),attrs:{"for":"message_content"}},[_vm._v(" Bericht ")]),_c('div',{class:_vm.skeletonVariants({ class: 'h-full rounded-lg bg-white' })})]),_c('div',{staticClass:"flex items-center justify-between px-2"},[_c('span',{class:_vm.skeletonVariants({ class: 'h-4 w-1/4 bg-white' })}),_c('div',{class:_vm.skeletonVariants({ class: 'h-6 w-2/5 rounded-full bg-white' })})])]:(_vm.status === 'success' && !!_vm.data)?[_c('div',{staticClass:"flex flex-col gap-1"},[_c('label',{class:_vm.labelVariants({ class: 'leading-5 text-gray-600' }),attrs:{"for":"message_title"}},[_vm._v(" Titel ")]),_c('input',{class:_vm.inputVariants({ class: 'rounded-lg border-gray-300 leading-normal' }),attrs:{"id":"message_title","readonly":""},domProps:{"value":_vm.data.title}})]),_c('div',{staticClass:"flex flex-1 flex-col gap-1"},[_c('label',{class:_vm.labelVariants({ class: 'leading-5 text-gray-600' }),attrs:{"for":"message_content"}},[_vm._v(" Bericht ")]),_c('textarea',{class:_vm.textareaVariants({
						class: 'm-0 flex-1 resize-none rounded-lg border-gray-300 text-black',
					}),attrs:{"id":"message_content","readonly":""},domProps:{"value":_vm.data.content}})]),_c('div',{staticClass:"flex items-center justify-between px-2"},[_c('span',{staticClass:"truncate text-xs"},[_vm._v("Automatisch bericht")]),_c('div',{staticClass:"flex items-center justify-end gap-1"},[_c('span',{class:_vm.badgeVariants({ class: 'gap-1 px-2 py-1 text-xs font-normal' }),style:({ color: '#7E300F', backgroundColor: '#FFEEDE' })},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"}})]),_vm._v(" Wordt verzonden op "),_c('time',{attrs:{"datetime":_vm.data.scheduledOn}},[_vm._v(" "+_vm._s(_vm.formatDate(new Date(_vm.data.scheduledOn), { dateStyle: "short", timeStyle: "short", }))+" ")])])])])]:_c('span',{staticClass:"text-error-active"},[_vm._v("Er ging iets mis.")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }